<template>
  <div class="container mb-3">
    <h2>
      <strong>
        Sign in to
        <span :style="`color: ${$application.theme.primary}`">{{ $application.name }}</span> with your Silicon Digital account
      </strong>
    </h2>
    <div class="form-group">
      <label for="email">Email Address</label>
      <input
        name="email"
        class="form-control form-control-lg"
        v-model="email"
        type="email"
        @input="errors.email = null"
        :class="{ 'is-invalid': errors.email }"
      />
      <span v-if="errors.email" class="invalid-feedback" role="alert">
        <strong>{{ errors.email }}</strong>
      </span>
    </div>
    <div class="form-group">
      <label for="password">Password</label>
      <input
        name="password"
        type="password"
        v-model="password"
        class="form-control form-control-lg"
        @input="errors.password = null"
        :class="{ 'is-invalid': errors.password }"
      />
      <span v-if="errors.password" class="invalid-feedback" role="alert">
        <strong>{{ errors.password }}</strong>
      </span>
    </div>
    <button
      class="btn mt-3 center btn-dark"
      :style="`background-color: ${$application.theme.primary}`"
      @click="handleLogin"
    >Sign In to {{ $application.name }}</button>


    <div class="mt-5">
      <router-link class="text-muted" to="/forgot-password/">
        <strong>CANT SIGN IN?</strong>
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: "Login",

  metaInfo: {
    title: "Login"
  },

  data() {
    return {
      email: null,
      password: null,
      errors: {
        email: null,
        password: null
      }
    };
  },

  methods: {
    handleLogin() {
      this.errors = {
        email: null,
        password: null,
      };

      this.$vToastify.loader(`Signing in to ${this.$application.name}...`);

      this.axios.get("/sanctum/csrf-cookie").then(() => {
        this.axios
          .post("/api/v1/auth/login", {
            email: this.email,
            password: this.password
          })
          .then(() => {
            this.$vToastify.stopLoader();
            this.$vToastify.success(`Signed in to ${this.$application.name}`);
            this.$root.getApplication();
            this.$router.push("/");
          })
          .catch((error) => {
            this.$vToastify.stopLoader();
            const statusCode = error.response.status;

            if (statusCode === 422) {
              const errors = error.response.data.errors;

              Object.keys(errors).forEach((key) => {
                this.errors[key] = errors[key][0];
              });

              this.$vToastify.error(error.response.data.message);
            }
          });
      })
      .catch(() => {
          this.$vToastify.stopLoader();
          this.$vToastify.error('CSRF error, try clearing your cookies.')
        });
    }
  }
};
</script>

<style scoped>
  .apple-signin {
    width: 230px;
    height: 49px;
    margin-top: 1em;
  }
</style>