<template>
  <div class="container mb-3">
    <h2>
      <strong>Reset your Silicon Digital account password</strong>
    </h2>

    <div v-if="emailFromQuery">
      <div class="form-group">
        <label for="email">Email</label>
        <input
          name="email"
          type="text"
          :value="emailFromQuery"
          class="form-control form-control-lg"
          readonly
          :class="{ 'is-invalid': errors.email }"
          @input="errors.email = null"
        />
        <span v-if="errors.email" class="invalid-feedback" role="alert">
          <strong>{{ errors.email }}</strong>
        </span>
      </div>
      <div class="form-group">
        <label for="new">New Password</label>
        <input
          name="new"
          type="password"
          v-model="password"
          class="form-control form-control-lg"
          :class="{ 'is-invalid': errors.password }"
          @input="errors.password = null"
        />
        <span v-if="errors.password" class="invalid-feedback" role="alert">
          <strong>{{ errors.password }}</strong>
        </span>
      </div>
      <div class="form-group">
        <label for="again">Confirm Password</label>
        <input
          name="again"
          type="password"
          v-model="confirmedPassword"
          class="form-control form-control-lg"
          :class="{ 'is-invalid': errors.confirmedPassword }"
          @input="errors.confirmedPassword = null"
        />
        <span v-if="errors.confirmedPassword" class="invalid-feedback" role="alert">
          <strong>{{ errors.confirmedPassword }}</strong>
        </span>
      </div>

      <button
        @click="submitReset"
        class="btn center btn-primary"
        :style="`background-color: ${$application.theme.primary}`"
      >Reset Password</button>
    </div>

    <div v-else>
      <h4>The email address is missing from the reset URL, please follow the correct link in your email.</h4>
    </div>
  </div>
</template>

<script>
export default {
  name: "Register",

  metaInfo: {
    title: "Register"
  },

  data() {
    return {
      password: null,
      confirmedPassword: null,
      errors: {
        email: null,
        password: null,
        confirmedPassword: null
      }
    };
  },

  computed: {
    emailFromQuery() {
      if (this.$route.query.email) {
        return this.$route.query.email;
      }

      return null;
    }
  },

  methods: {
    submitReset() {
      this.$vToastify.loader("Resetting Password");

      this.axios
        .post("/api/v1/auth/password/reset", {
          token: this.$route.params.token,
          email: this.emailFromQuery,
          password: this.password,
          password_confirmation: this.confirmedPassword
        })
        .then(() => {
          this.$vToastify.stopLoader();
          this.$vToastify.success("Password reset, automatically logging in.");
          this.$root.getApplication();
          this.$router.push("/");
        })
        .catch(error => {
          this.$vToastify.stopLoader();

          const statusCode = error.response.status;

          if (statusCode === 422) {
            const errors = error.response.data.errors;

            Object.keys(errors).forEach(key => {
              this.errors[key] = errors[key][0];
            });

            this.$vToastify.error(error.response.data.message);
          }
        });
    }
  }
};
</script>