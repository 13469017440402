<template>
  <div class="container mb-3">
    <h2>
      <strong>Verify Email</strong>
    </h2>
    <h4>
      We've sent an email to <p v-if="isApplePrivateEmail">your private <strong>Apple ID</strong> email</p>
      <strong v-else>{{ $user.email }}</strong>, click the link in the email to verify your account.
    </h4>

    <div class="mt-3">
      <a class="text-muted" href="#" @click="resend">
        <strong>RESEND EMAIL</strong>
      </a>
      <br />
      <a class="text-muted" href="#" @click="$root.logout()">
        <strong>LOGOUT</strong>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: "Verify Email",

  data() {
    return {
      sendingVerificationEmail: false,
    }
  },

  computed: {
    isApplePrivateEmail() {
      const emailDomain = this.$user.email.split('@').pop();
      
      return emailDomain === 'privaterelay.appleid.com';
    }
  },

  methods: {
    resend() {
      if (this.sendingVerificationEmail) {
        return;
      }

      this.sendingVerificationEmail = true;
      this.$vToastify.loader('Resending verification email...');
      
      this.axios.post('/api/v1/auth/email/resend')
        .then(() => {
          this.sendingVerificationEmail = false;
          this.$vToastify.stopLoader();
          this.$vToastify.success('Verification email resent');
        })
        .catch(() => {
          this.sendingVerificationEmail = false;
          this.$vToastify.stopLoader();
          this.$vToastify.error('Something went wrong when trying to resend verification email.');
        })
    },
  },

  mounted() {
    if (!this.$user) {
      this.$router.push("/login");
    } else {
      if (this.$user.verified) {
        this.$router.push("/");
      }
    }
  }
};
</script>