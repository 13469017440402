<template>
  <div class="container mb-3">
    <h2>
      <strong>Account Suspended</strong>
    </h2>
    <h4>
      Your Silicon Digital account has been suspended and cannot be used on
      <span :style="`color: ${$application.theme.primary}`">{{ $application.name }}</span>.
    </h4>
    <div class="mt-3">
      <a class="text-muted" @click="$root.logout" href="#">
        <strong>LOGOUT</strong>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: "suspended",

  metaInfo: {
    title: "Suspended"
  },

  mounted() {
    if (!this.$user) {
      this.$router.push('/login');
    }
  },
};
</script>