<template>
  <div class="container mb-3">
    <h2>
      <strong>
        Create a Silicon Digital account to sign in to
        <span
          :style="`color: ${$application.theme.primary}`"
        >{{ $application.name }}</span>
      </strong>
    </h2>
    <div class="form-group">
      <label for="name">Name</label>
      <input
        name="name"
        type="text"
        v-model="name"
        class="form-control form-control-lg"
        :class="{ 'is-invalid': errors.name }"
      />
      <span v-if="errors.name" class="invalid-feedback" role="alert">
        <strong>{{ errors.name }}</strong>
      </span>
    </div>
    <div class="form-group">
      <label for="email">Email Address</label>
      <input
        name="email"
        type="text"
        v-model="email"
        class="form-control form-control-lg"
        :class="{ 'is-invalid': errors.email }"
      />
      <span v-if="errors.email" class="invalid-feedback" role="alert">
        <strong>{{ errors.email }}</strong>
      </span>
    </div>
    <div class="form-group">
      <label for="password">Password</label>
      <input
        name="password"
        type="password"
        v-model="password"
        class="form-control form-control-lg"
        :class="{ 'is-invalid': errors.password }"
      />
      <span v-if="errors.password" class="invalid-feedback" role="alert">
        <strong>{{ errors.password }}</strong>
      </span>
    </div>
    <div class="form-group">
      <label for="again">Confirm Password</label>
      <input
        name="again"
        type="password"
        v-model="confirmedPassword"
        class="form-control form-control-lg"
      />
    </div>
    <button
      class="btn center btn-dark mt-3"
      @click="register"
      :style="`background-color: ${$application.theme.primary}`"
    >Create Account</button>

    <div class="mt-5">
      <router-link class="text-muted" to="login">
        <strong>ALREADY HAVE AN ACCOUNT?</strong>
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: "Register",

  metaInfo: {
    title: "Register"
  },

  data() {
    return {
      name: null,
      email: null,
      password: null,
      confirmedPassword: null,
      errors: {
        name: null,
        email: null,
        password: null
      }
    };
  },

  methods: {
    register() {
      this.$vToastify.loader(`Creating Silicon Digital account...`);

      this.axios.get("/sanctum/csrf-cookie").then(() => {
        this.axios
          .post("/api/v1/auth/register", {
            name: this.name,
            email: this.email,
            password: this.password,
            password_confirmation: this.confirmedPassword
          })
          .then(async () => {
            this.$vToastify.stopLoader();
            await this.$root.getApplication();
            this.$router.push('/verify-email');
          })
          .catch(error => {
            this.$vToastify.stopLoader();
            const statusCode = error.response.status;

            if (statusCode === 422) {
              const errors = error.response.data.errors;

              Object.keys(errors).forEach(key => {
                this.errors[key] = errors[key][0];
              });

              this.$vToastify.error(error.response.data.message);
            }
          });
      });
    }
  }
};
</script>