<template>
  <div class="container mb-3">
    <h2>
      <strong>Reset your Silicon Digital account password</strong>
    </h2>

    <div v-if="!emailSent">
      <div class="form-group">
        <label for="email">Email Address</label>
        <input
          name="email"
          class="form-control form-control-lg"
          v-model="email"
          type="email"
          @input="errors.email = null"
          :class="{ 'is-invalid': errors.email }"
        />
        <span v-if="errors.email" class="invalid-feedback" role="alert">
          <strong>{{ errors.email }}</strong>
        </span>
      </div>

      <button
        @click="sendResetEmail"
        class="btn center btn-primary"
        :style="`background-color: ${$application.theme.primary}`"
      >Send Reset Link</button>
    </div>

    <div v-else>
      <h4>
        We've sent a reset email to
        <strong>{{ email }}</strong>,
        click the link in the email to reset your Silicon Digital account password.
      </h4>
      <br />
      <h5 class="text-muted">
        Sometimes our emails land in your Junk / Spam folders, make sure to check them for
        the reset email.
      </h5>
    </div>

    <div class="mt-3">
      <router-link class="text-muted" to="/login/">
        <strong>BACK TO LOGIN</strong>
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: "Forgot Password",

  metaInfo: {
    title: "Forgot Password"
  },

  data() {
    return {
      email: null,
      errors: {
        email: null
      },
      emailSent: false
    };
  },

  methods: {
    sendResetEmail() {
      this.$vToastify.loader("Sending reset email...");
      this.axios
        .post("/api/v1/auth/password/email", {
          email: this.email
        })
        .then(() => {
          this.$vToastify.stopLoader();
          this.emailSent = true;
        })
        .catch(error => {
          this.$vToastify.stopLoader();
          const statusCode = error.response.status;

          if (statusCode === 422) {
            const errors = error.response.data.errors;

            Object.keys(errors).forEach(key => {
              this.errors[key] = errors[key][0];
            });

            this.$vToastify.error(error.response.data.message);
          }
        });
    }
  }
};
</script>