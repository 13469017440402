<template>
  <div>
    <background>
      <component v-if="$application && !$user" :is="$route.name" />
      <already-signed-in v-if="$user && $route.name !== 'suspended' && $route.name !== 'verify-email'" />
      <verify-email v-if="$user && $route.name !== 'suspended' && $route.name === 'verify-email'" />
      <suspended v-if="$user && $user.restricted && $route.name === 'suspended'" />
    </background>
  </div>
</template>

<script>
import Background from "./partials/Background";
import Login from "./partials/Login";
import Register from "./partials/Register";
import VerifyEmail from "./partials/VerifyEmail";
import ForgotPassword from "./partials/ForgotPassword";
import ResetPassword from "./partials/ResetPassword";
import AlreadySignedIn from "./partials/AlreadySignedIn";
import Suspended from "./partials/Suspended";

export default {
  name: "signon",

  components: {
    Background,
    Login,
    Register,
    VerifyEmail,
    ForgotPassword,
    ResetPassword,
    AlreadySignedIn,
    Suspended
  },

  mounted() {
    if (
      this.$route.name === "suspended" &&
      this.$user &&
      !this.$user.restricted
    ) {
      this.$router.push("/");
    }
  }
};
</script>