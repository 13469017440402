<template>
  <div class="container mb-3">
    <h2>
      <strong>
        You're already signed in to
        <span :style="`color: ${$application.theme.primary}`">{{ $application.name }}</span> with your Silicon Digital account
      </strong>
    </h2>
    <h4>The action you're trying to do requires you to be logged out, would you like to logout?</h4>

    <div class="mt-3">
      <a class="text-muted text-uppercase" @click="$root.logout()" href="#">
        <strong>Yes, Log me out</strong>
      </a>
      <br>
      <router-link class="text-muted text-uppercase" to="/">
        <strong>Go to {{ $application.name }}</strong>
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: "Already Signed In",

  metaInfo: {
    title: 'Logged In',
  }
};
</script>